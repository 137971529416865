import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=6abb5ed3&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=6abb5ed3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6abb5ed3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFooter,VRow})
